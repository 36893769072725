.k-animation-container {
  z-index: 10003;
}
.k-calendar-container {
  position: relative;
}

legend {
  font-size: 14px;
  font-weight: 600;  
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 16px 0 0;
}

.question {
  margin-bottom: 10px;
}

.question .k-radio-label {
  margin-bottom: 5px;
}

.cancel-questions-container {
  max-height: 400px;
  overflow-y: auto;
}

.cancel-questions-container p {
  margin-top: 0;
}