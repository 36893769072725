.mainWrapper {
  height: 100%;
  width: 100%;
  /* overflow: scroll; */
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 150px);
}

.k-animation-container {
  width: 100% !important;
}
.secondaryWrapper {
  height: 10%;
  width: 100%;
}

.tabs {
  width: 100%;
}
body .lm_content {
  background-color: lightgray;
  /* overflow-y: scroll; */
  border: 0;
}
body .lm_item_container {
  border: 0;
}

.k-dialog-wrapper .k-dialog{
  /* top: 175px; */
  /* position: absolute; */
  overflow: auto;
}

.secondary-screen .lm_content {
  overflow: auto;
}
