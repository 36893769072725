body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.k-checkbox-label {
  font-size: 18px;
  /* font-weight: bold; */
}

.labelInput {
  margin-top: -10px;
  width: 100%;
}

td {
  padding: 10px;
}

.formButtons {
  float: left;
}

.taleManus {
  font-size: 22px;
}

.taleManus span {
  padding-bottom: 1px;
  display: block;
}

.formLabels {
  font-size: 18px;
  font-weight: bold;
  display: inline;
}
.formTextInput {
  width: 100%;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;
}

/* .k-radio-label {
  font-size: 18px;
  font-weight: bold;
} */

tr {
  padding: 10px;
}

.formButtons {
  float: left;
}

.carousel .slide img {
  max-height: 300px;
  width: auto;
}

/* .secondStepTable tr:nth-child(even) {
  background-color: lightgrey;
} */

.mainWrapper {
  height: 100%;
  width: 100%;
  /* overflow: scroll; */
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 150px);
}

.k-animation-container {
  width: 100% !important;
}
.secondaryWrapper {
  height: 10%;
  width: 100%;
}

.tabs {
  width: 100%;
}
body .lm_content {
  background-color: lightgray;
  /* overflow-y: scroll; */
  border: 0;
}
body .lm_item_container {
  border: 0;
}

.k-dialog-wrapper .k-dialog{
  /* top: 175px; */
  /* position: absolute; */
  overflow: auto;
}

.secondary-screen .lm_content {
  overflow: auto;
}

.btnWrapper {
  padding: 10px;
  /* background: white; */
  height: 100%;
}

.k-animation-container {
  z-index: 10003;
}
.k-calendar-container {
  position: relative;
}

legend {
  font-size: 14px;
  font-weight: 600;  
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 16px 0 0;
}

.question {
  margin-bottom: 10px;
}

.question .k-radio-label {
  margin-bottom: 5px;
}

.cancel-questions-container {
  max-height: 400px;
  overflow-y: auto;
}

.cancel-questions-container p {
  margin-top: 0;
}
.mapouter {
    text-align: right;
    height: 100%;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
}

.historyTable tr:nth-child(even) {
  /* border: 1px solid lightgray; */
  background-color: lightgrey;
  padding: 30px;
}

.orderComTable tr:nth-child(even) {
  /* border: 1px solid lightgray; */
  background-color: lightgrey;
}

.articlesTable {
  border: 1px solid black;
}

.articlesTable tr:nth-child(even) {
  /* border: 1px solid lightgray; */
  background-color: lightgrey;
}

