.k-checkbox-label {
  font-size: 18px;
  /* font-weight: bold; */
}

.labelInput {
  margin-top: -10px;
  width: 100%;
}

td {
  padding: 10px;
}

.formButtons {
  float: left;
}

.taleManus {
  font-size: 22px;
}

.taleManus span {
  padding-bottom: 1px;
  display: block;
}

.formLabels {
  font-size: 18px;
  font-weight: bold;
  display: inline;
}
.formTextInput {
  width: 100%;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;
}
