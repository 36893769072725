/* .k-radio-label {
  font-size: 18px;
  font-weight: bold;
} */

tr {
  padding: 10px;
}

.formButtons {
  float: left;
}

.carousel .slide img {
  max-height: 300px;
  width: auto;
}

/* .secondStepTable tr:nth-child(even) {
  background-color: lightgrey;
} */
